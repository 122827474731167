
import axios from "axios";
import headings from "~/mixins/headings.js";

export default {
  mixins: [headings],
  async asyncData({ store, error, params }) {
    try {
      const { data } = await axios.get(
        `${store.state.wsPath}get?type=collections/${params.id}&lang=${store.state.lang}&market=${store.state.market}`
      );
      return {
        data,
      };
    } catch (e) {
      error({ statusCode: 500, message: store.state.labels.error_occurred });
    }
  },
};
