import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  alt: {
    type: String,
    required: false,
  },
  image: {
    type: String,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "lazy",
  },
},
  setup(__props) {

const props = __props



const tpl_root = templateRef("tpl_root");
const tpl_media = templateRef("tpl_media");

onMounted(() => {
  requestAnimationFrame(() => {
    gsap.from(tpl_media.value.$el, {
      scrollTrigger: {
        trigger: tpl_root.value,
        start: "clamp(top bottom)",
        end: "clamp(top 10%)",
        scrub: 1,
      },
      transformOrigin: "center",
      ease: "sine.out",
      scale: 1.1,
      force3D: "auto",
    });
  });
});

return { __sfc: true,props, tpl_root, tpl_media }
}

}