import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=c314d9fe&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockHeaderCollection: require('/var/www/html/fantini/frontend/components/block/header/collection/index.vue').default,BlockSlideImage: require('/var/www/html/fantini/frontend/components/block/slide/image/index.vue').default,BlockTxtIntro: require('/var/www/html/fantini/frontend/components/block/txt/intro/index.vue').default,BlockGalleryMultislide: require('/var/www/html/fantini/frontend/components/block/gallery/multislide/index.vue').default,BlocksWrapper: require('/var/www/html/fantini/frontend/components/blocks-wrapper/index.vue').default,BlockTxtHuge: require('/var/www/html/fantini/frontend/components/block/txt/huge/index.vue').default,Fig: require('/var/www/html/fantini/frontend/components/fig/index.vue').default,CardLauncherTraditional: require('/var/www/html/fantini/frontend/components/card/launcher/traditional/index.vue').default,GridCss: require('/var/www/html/fantini/frontend/components/grid/css/index.vue').default,Block: require('/var/www/html/fantini/frontend/components/block/index.vue').default,BlockBtnGroupSolid: require('/var/www/html/fantini/frontend/components/block/btn/group/solid/index.vue').default,BlockRecirculationSubtree: require('/var/www/html/fantini/frontend/components/block/recirculation/subtree/index.vue').default,Page: require('/var/www/html/fantini/frontend/components/page/index.vue').default})
