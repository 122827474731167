import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";
import { useDownloadables } from "/composables/useDownloadables";
import { useDataLayer } from "@/composables/useDataLayer";

export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const tpl_group = templateRef("tpl_group");
const tpl_titleWrapper = templateRef("tpl_titleWrapper");
const tpl_title = templateRef("tpl_title");
const { reveal } = useScrollTriggerAnimation();
const { pushDataLayer } = useDataLayer();

function getFileExtension(fileName) {
  const patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  return fileName.match(patternFileExtension)[1];
}

function getIconGrpx(item) {
  let grpx;

  if (item.download) {
    grpx = "download";
  } else if (item.externalLink) {
    grpx = "arrow-long-r-light";
  } else {
    grpx = "arrow-long-r-light";
  }
  return grpx;
}

onMounted(() => {
  if (props.dataProvider.title) {
    reveal(tpl_title.value, tpl_titleWrapper.value, "bottom", {
      intensity: "strong",
    });
  }

  reveal(tpl_group.value, tpl_group.value, "alternate-x", {
    intensity: "strong",
    fade: false,
    useChildren: true,
    duration: "long",
    stagger: "none",
  });
});

const { getLink } = useDownloadables();

return { __sfc: true,props, tpl_group, tpl_titleWrapper, tpl_title, reveal, pushDataLayer, getFileExtension, getIconGrpx, getLink }
}

}