import { useStore } from "@nuxtjs/composition-api";
import { onMounted, computed } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const store = useStore();
const { reveal } = useScrollTriggerAnimation();
const titleLabel = computed(() => {
  let s;

  switch (props.dataProvider.variant) {
    case "designers":
      s = store.state.labels.other_designers;
      break;

    case "series":
      s = store.state.labels.other_series;
      break;
  }

  return s;
});

const tpl_root = templateRef("tpl_root");
const tpl_bg = templateRef("tpl_bg");
const tpl_titleWrapper = templateRef("tpl_titleWrapper");
const tpl_title = templateRef("tpl_title");
const tpl_buttonWrapper = templateRef("tpl_buttonWrapper");
const tpl_button = templateRef("tpl_button");

onMounted(() => {
  requestAnimationFrame(() => {
    reveal(tpl_bg.value, tpl_root.value.$el, "bottom", {
      intensity: "strong",
      duration: "long",
    });

    if (titleLabel.value) {
      reveal(tpl_title.value, tpl_titleWrapper.value, "top", {
        intensity: "strong",
        fade: true,
        duration: "long",
      });
    }

    if (props.dataProvider.variant === "series") {
      reveal(tpl_button.value.$el, tpl_buttonWrapper.value, "bottom", {
        intensity: "strong",
        fade: true,
        duration: "long",
      });
    }
  });
});

return { __sfc: true,props, store, reveal, titleLabel, tpl_root, tpl_bg, tpl_titleWrapper, tpl_title, tpl_buttonWrapper, tpl_button }
}

}