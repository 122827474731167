import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  parallax: {
    type: Boolean,
    required: false,
    default: true,
  },
  aspectRatio: {
    type: String,
    required: true,
    validator: function (value) {
      return ["1 / 1", "4 / 5", "3 / 2", "16 / 9"].indexOf(value) !== -1;
    },
  },
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["normal", "tight", "swatch"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  media: {
    type: Object,
    required: true,
  },
  mediaSizes: {
    type: Object,
    required: false,
  },
  overline: {
    type: String,
    required: false,
  },
  caption: {
    type: String,
    required: false,
  },
  mt: {
    type: String,
    required: false,
    validator: function (value) {
      return ["normal", "tight"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
},
  setup(__props) {

const props = __props



const tpl_figcaption = templateRef("tpl_figcaption");
const { reveal } = useScrollTriggerAnimation();

onMounted(() => {
  if (props.parallax && (props.overline || props.caption)) {
    reveal(tpl_figcaption.value, tpl_figcaption.value, "right", {
      intensity: "normal",
      fade: true,
      duration: "long",
      useChildren: true,
      stagger: "normal",
    });
  }
});

return { __sfc: true,props, tpl_figcaption, reveal }
}

}