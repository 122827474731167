import { ref, onMounted } from "vue";
import { useWindowSize, debouncedWatch, templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  text: {
    type: String,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "p",
  },
},
  setup(__props) {

const props = __props



const { width } = useWindowSize();
const tpl_root = templateRef("tpl_root");
const tpl_text = templateRef("tpl_text");
const splittedText = ref(false);

debouncedWatch(
  width,
  () => {
    handleSplit();
  },
  { debounce: 250 }
);

function handleSplit() {
  splittedText.value.split({
    type: "lines",
    linesClass: "line",
  });

  gsap.killTweensOf(splittedText.value.lines);

  requestAnimationFrame(() => {
    gsap.from(splittedText.value.lines, {
      scrollTrigger: {
        trigger: tpl_root.value,
        start: "top bottom",
        toggleActions: "restart none none reset",
      },
      stagger: 0.05,
      duration: 0.85,
      ease: "circ.out",
      x: gsap.utils.wrap([32, -32]),
      autoAlpha: 0,
      force3D: "auto",
    });
  });
}

onMounted(() => {
  splittedText.value = new SplitText(tpl_text.value);
  handleSplit();
});

return { __sfc: true,props, width, tpl_root, tpl_text, splittedText, handleSplit }
}

}